
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PROPIMG from '../assets/imgs/propertyImg.png'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import HandIcon from '../assets/imgs/HandIconGreen.svg'
import ExpansionSummary from './ExpansionSummary'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


function TabsSection() {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <>
      <div className="ExpansionTabBar">
        <div className="ExpansionContainer">
          <Grid container spacing={3}>

            <Grid item md={8} xs={12}>
              <Tabs
                value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered >
                <Tab label="Description" href="#Description"/>
                <Tab label="Project Phase" href="#Project-Phase"/>
                <Tab label="Updates" href="#Updates"/>
                <Tab label="Guests" href="#Guests"/>
                <Tab label="Testimonials" href="#Testimonials"/>
                <Tab label="Community" href="#Community"/>
              </Tabs>
            </Grid>

            <Grid item md={4} xs={12}>
              <div className="dontaineButnWhite">
                <div className="ExpansionHeaderDontainerButtonContainer">
                  <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
                    <img className="ExpansionFillButtonIcon" src={HandIcon} /> Donate now</Button>
                </div>
              </div>
            </Grid>

          </Grid>

          

        </div>
      </div>

      <div className="ExpansionContainer">

        <div id="Description" className="ExpansionTabsContainer">
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <div className="TabsContainerPannel">
                <h1>Ground level interior bricks must be installed before it gets too cold! Donate your brick package now!</h1>
                <p>The 60,000 Sq Ft NYC Muslim Center is being built at the same location. The Prophet, peace be upon him, said:</p>
                <div className="TabsContainerQoute">“Whoever removes hardship for a believer in this world, Allah will remove hardship from him on the Day of Judgment.”</div>
                <div className="propertyImg"><img src={PROPIMG} /></div>
                <p>Shaykh Abdullah Danish has authored over 20 books, has had over 1000 students, and built Masjid al-Badr in Brooklyn, NY 25 years ago from scratch! He is a gem that is loved by the Muslim community.</p>
                <p>Shaykh Abdullah Danish has authored over 20 books, has had over 1000 students.</p>
                {/* <Link className="TextReadMore">READ MORE <KeyboardArrowDownRoundedIcon /></Link>  */}
              </div >
            </Grid>

            <Grid item md={4} xs={12}>
              <ExpansionSummary />
              {/* <DonaitionCategories /> */}
            </Grid>


          </Grid>
        </div>
      </div>


    </>


  );
}










export default TabsSection