import { Grid, Avatar, IconButton } from "@material-ui/core";
import React from 'react'

const ImageGrid = (props) => {
  const { users, index, setIndex } = props;

  return (
    <div className="TestimonialThumbnailContainer">
    <Grid item lg={10} md={10} sm={12} xs={12} container={true} spacing={1}>
      {users.map((data) => {
        return (
          <Grid item>
            <IconButton
              size="small"
              component="span"
              onClick={() => setIndex(data.id)}
              style={{
                backgroundColor: data.id === index ? "#56B149" : ""
              }}
            >
              <Avatar alt={data.name} src={data.img} className="AurtherThumbnail"/>
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
    </div>
  );
};

export default ImageGrid;
