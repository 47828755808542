
import React from 'react'
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import Mosque from '../assets/imgs/masjid.jfif';

import Carousel from 'react-elastic-carousel'
import Item from './Item'





const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

function ProjectPhase() {


  return (


  <>

     


    <div className="ExpansionProjectPhaseContainer LighGreyContainer">
      <div id="Project-Phase" className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Project Phase</h1>

        
        <div className="ProjectPhaseContainer">
        <Carousel breakPoints={breakPoints}> 
          {/* <div className="ProjectPhaseBox PhaseActive Done">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Architectural Plans</div>
            </div>
          </div> */}

          <div className="ProjectPhaseBox PhaseActive Inprogress">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Architectural Plans</div>
            </div>
          </div>

          <div className="ProjectPhaseBox Default">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Buildout</div>
            </div>
          </div>

          <div className="ProjectPhaseBox Default">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Expansion</div>
            </div>
          </div>

          <div className="ProjectPhaseBox Default">
            <div className="ProjectPhaseProgressBox">
              <div className="ProjectPhaseProgressCircul"></div>
            </div>

            <div className="ProjectPhaseCard arrow-up">
              <div className="ProjectPhaseStatus">
                <HourglassFullRoundedIcon className="defaultIcon" />
                <HourglassEmptyRoundedIcon className="InProgressIcon" />
                <DoneRoundedIcon className="DoneIcon" />
              </div>
              <div className="ProjectPhaseCardTitle">Completion</div>
            </div>
          </div>
          </Carousel> 
        </div>
        

      </div>

    </div>



    </>
  );
}


export default ProjectPhase