import { Grid, IconButton } from "@material-ui/core";
import React from 'react'

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const SlideButton = (props) => {
  const { users, index, setIndex } = props;
  return (
    
    <div className="TestimonialArrowButton">
      <IconButton className="leftArrow TsArrow"
        size="small"
        component="span"
        onClick={() => {
          setIndex(index === 1 ? 8 : index - 1);
        }}
      >
        <ArrowBackIosRoundedIcon fontSize="inherit" style={{ color: "#0B132B" }} />
      </IconButton>
      <IconButton className="RightArrow TsArrow"
        size="small"
        component="span"
        onClick={() => {
          setIndex(index === 8 ? 1 : index + 1);
        }}
      >
        <ArrowForwardIosRoundedIcon fontSize="inherit" style={{ color: "#0B132B" }} />
      </IconButton>
      </div>
  );
};

export default SlideButton;
