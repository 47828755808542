import { Paper, IconButton } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React from 'react'

const VideoComponent = (props) => {
  const { users, index } = props;
  return (
    <Paper className="TestimonialVideoImg"
      variant="outlined"
      square
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundImage: `url(${users[index - 1].video})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
    >
      {/* <img alt={users[index - 1].name} src={users[index - 1].img} /> */}
      <IconButton
        component="span"
        style={{ backgroundColor: "#9ad092", color: "white" }}
      >
        <PlayArrowIcon />
      </IconButton>
    </Paper>
  );
};

export default VideoComponent;
