import React, { useState, useEffect }  from 'react';
import "./Testimonials/testimonials.css";
import { Grid, Paper, Divider, Avatar, IconButton, } from "@material-ui/core";
import VideoComponent from "./Testimonials/videoComponent";
import DetailComponent from "./Testimonials/detailComponent";
import ImageGrid from "./Testimonials/imageGrid";
import SlideButton from "./Testimonials/slideButton";
import { users } from "./Testimonials/users.js";

function Testimonials() {

  let [index, setIndex] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index === 8 ? 1 : index + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className="ExpansionTestimonialsContainer">
      <div id="Testimonials" className="ExpansionContainer" >
      <SlideButton users={users} index={index} setIndex={setIndex} />
        <Grid container spacing={6}>

          <Grid item md={5} xs={12} className="videoPhotoMoble">
            <VideoComponent users={users} index={index} />
          </Grid>

          <Grid item md={7} xs={12} >
            <Grid container spacing={3}>
              <DetailComponent users={users} index={index} />
              
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <ImageGrid users={users} index={index} setIndex={setIndex} />
                
              </div>
            </Grid>
          </Grid>

        </Grid>
       
      </div>
    </div>
  );
}

export default Testimonials;
