
import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import Masjid from '../assets/imgs/masjid.jfif'
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import HandIcon from '../assets/imgs/HandIcon.svg'



const ConstructionUpdates = (props) => {

  return (

    <div className="ExpansionConstructionUpdatesContainer LighGreyContainer">
      <div id="Updates" className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Construction Updates</h1>
        <div className="MobileScroll">
        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className="TimeLineDate">Feb 21, 2021</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Accordion>

              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className="accourdianTitle" >Our mission is to support people who want to make an impact in their communities. We do that in various ways, Our mission is to support people who want to make an impact in their communities. We do that in various ways, </Typography>
              </AccordionSummary>

              <AccordionDetails>

                <div className="autherName">by Khalil Ahmad</div>

                <div className="ConstructionUpdatesGallery">

                  <Grid container spacing={1}>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                  </Grid>
                </div>

              </AccordionDetails>
            </Accordion></TimelineContent>
          </TimelineItem>
        </Timeline>


        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className="TimeLineDate">Feb 21, 2021</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Accordion>

              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className="accourdianTitle" >Our mission is to support people who want to make an impact in their communities. We do that in various ways, Our mission is to support people who want to make an impact in their communities. We do that in various ways, </Typography>
              </AccordionSummary>

              <AccordionDetails>

                <div className="autherName">by Khalil Ahmad</div>

                <div className="ConstructionUpdatesGallery">

                  <Grid container spacing={1}>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                  </Grid>
                </div>

              </AccordionDetails>
            </Accordion></TimelineContent>
          </TimelineItem>
        </Timeline>



        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className="TimeLineDate">Feb 21, 2021</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Accordion>

              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className="accourdianTitle" >Our mission is to support people who want to make an impact in their communities. We do that in various ways, Our mission is to support people who want to make an impact in their communities. We do that in various ways, </Typography>
              </AccordionSummary>

              <AccordionDetails>

                <div className="autherName">by Khalil Ahmad</div>

                <div className="ConstructionUpdatesGallery">

                  <Grid container spacing={1}>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={Masjid} />
                      </div>
                    </Grid>

                  </Grid>
                </div>

              </AccordionDetails>
            </Accordion></TimelineContent>
          </TimelineItem>
        </Timeline>
        </div>

        <div className="ButtonCenter groupButton">
              <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
              <img className="ExpansionFillButtonIcon" src={HandIcon} /> Support us now</Button>

               <Button className="ExpansionFillButton white" variant="contained" href="#contained-buttons">SHOW PAST updates</Button>
            </div>
      </div>
    </div>



  );
}


export default ConstructionUpdates