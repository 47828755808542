import { Grid } from "@material-ui/core";
import React from 'react'


const DetailComponent = (props) => {
  const { users, index } = props;
  return (
    <>
      <div className="TestimonialTitle">
        {users[index - 1].title}
      </div>

      <div className="TestimonialDescription">
        "{users[index - 1].description}"
      </div>

      <div className="TestimonialAurtherText">
        {users[index - 1].name}
        <br />
        {users[index - 1].address}
      </div>
    </>
  );
};

export default DetailComponent;
