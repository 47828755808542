
import React from 'react'
import Grid from '@material-ui/core/Grid';
import profPhoto from '../assets/imgs/profilePhoto.jfif'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import Button from '@material-ui/core/Button';



function OurGuests() {


  return (

    <div className="ExpansionOurGuestsContainer">

      <div id="Guests" className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Our guests</h1>

        <div className="ExpansionCardContainer">
          <Grid container spacing={3}>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <div className="guestsCard">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                  <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>David Barrere</h2>
                <p>NYPD Assistant Chief</p>
                
              </div>
            </Grid>

          </Grid>
        </div>
      </div>


    </div>



  );
}










export default OurGuests